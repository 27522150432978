import { createRouter, createWebHistory } from 'vue-router';
import getUser from "@/composables/getUser";
import useLogout from "@/composables/useLogout";

const requireAuth = (to, from, next) => {
  const { user, investor } = getUser();
  const loginPath = window.location.pathname;
  console.log("Require Auth?");
  if (!user.value) {
    console.log("No User!");
    next({ name: 'Login', query: { from: loginPath } });
  } else {
    console.log("We have a user!");
    if (!investor.value) {
      const { logout } = useLogout();
      logout().then(() => {
        console.log("Logging out the non-investor user");
        next({ name: 'Login', query: { from: loginPath } });
      });
    } else {
      next();
    }
  }
};

const requireNoAuth = async (to, from, next) => {
  const { user, investor } = getUser();
  if (user.value) {
    if (investor.value) {
      next({ name: 'Offerings' });
    } else {
      const { logout } = useLogout();
      logout().then(() => {
        console.log("Logging out the non-investor user");
        next({ name: 'Login' });
      });
    }
  } else {
    next();
  }
};



const routes = [
  {
    path: '/offerings',
    name: 'Offerings',
    component: () => import('@/views/offerings/Offerings.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/tags/:tag',
    name: 'OfferingsTag',
    component: () => import('../views/offerings/OfferingsTag.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offeringsWatch',
    name: 'OfferingsWatch',
    component: () => import('../views/offerings/OfferingsWatch.vue'),
    beforeEnter: requireAuth,
  },
  {
    // Keep offering singular so a company 'create' doesn't hose us up
    path: '/offering/:id',
    name: 'Offering',
    component: () => import('@/views/offerings/Offering.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offeringInvest/:id',
    name: 'OfferingInvest',
    component: () => import('@/views/offerings/OfferingInvest.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offeringInvest/:id/investments',
    name: 'OfferingInvestments',
    component: () => import('@/views/offerings/invest/OfferingInvestments.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offeringInvest/:id/investorDetails/:draftId',
    name: 'InvestorDetails',
    component: () => import('@/views/offerings/invest/InvestorDetails.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offeringInvest/:id/investmentDetails/:draftId',
    name: 'InvestmentDetails',
    component: () => import('@/views/offerings/invest/InvestmentDetails.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offeringInvest/:id/investmentReview/:draftId',
    name: 'InvestmentReview',
    component: () => import('@/views/offerings/invest/InvestmentReview.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offeringInvest/:id/investmentConfirmation/:draftId',
    name: 'InvestmentConfirmation',
    component: () => import('@/views/offerings/invest/InvestmentConfirmation.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/offeringInvest/:offeringId/investmentReceipt/:investmentId',
    name: 'InvestmentReceipt',
    component: () => import('@/views/offerings/invest/InvestmentReceipt.vue'),
    beforeEnter: requireAuth,
    props: true,
  },
  {
    path: '/investments',
    name: 'Investments',
    component: () => import('@/views/Investments.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/Account.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/login',
    alias: '/',
    name: 'Login',
    component: () => import('@/views/auth/Login.vue'),
    beforeEnter: requireNoAuth,
  },
  {
    path: '/signUp',
    name: 'SignUp',
    component: () => import('@/views/auth/SignUp.vue'),
    beforeEnter: requireNoAuth,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Offerings',
    component: () => import('@/views/offerings/Offerings.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('@/views/Welcome.vue'),
    beforeEnter: requireNoAuth,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      console.log(`No Saved Position: Hash = ${to.has}`);
      const position = {};
      position.el = to.hash;
      position.top = 80;
      if (document.querySelector(to.hash)) {
        console.log("Query Selector: to.hash:", to.hash);
        return position;
      }
    }
    if (savedPosition) {
      console.log("Saved Position! ToHash:", to.hash);
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});


export default router;
