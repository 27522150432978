import { ref } from 'vue';
import { projectAuth } from '../firebase/config';

// refs
const user = ref(projectAuth.currentUser);
const investor = ref(false);

// auth changes
projectAuth.onAuthStateChanged(_user => {
  console.log('User state change. Current user is:', _user);
  user.value = _user;
  if (user.value) {
    user.value.getIdTokenResult().then((getIdTokenResult) => {
      if (getIdTokenResult.claims.investor) {
        investor.value = true;
      } else {
        investor.value = false;
      }
    });
  }
});

// set role
const setInvestor = (value) => {
  investor.value = value;
};

const getUser = () => {
  return { user, investor, setInvestor };
};

export default getUser;